// 

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
		
		var waitForFinalEvent = (function () {
		  var timers = {};
		  return function (callback, ms, uniqueId) {
			if (!uniqueId) {
			  uniqueId = "Don't call this twice without a uniqueId";
			}
			if (timers[uniqueId]) {
			  clearTimeout (timers[uniqueId]);
			}
			timers[uniqueId] = setTimeout(callback, ms);
		  };
		})();
		/*jshint ignore:start */
		function carouselNormalization(ids) {
		  var items   = $(ids),              // grab all the slides
			  heights = [],                                   // array to store heights
			  tallest;                                        // tallest slide

		  if (items.length) {
			function normalizeHeights() {
			  items.each(function() {
				heights.push($(this).height());               // add each slide's height
			  });                                             // to the array

			  tallest = Math.max.apply(null, heights);        // find the largest height

			  items.each(function() {
				$(this).css('min-height', tallest + 'px');    // set each slide's minimum
			  });                                             // height to the largest
			}

			normalizeHeights();

			$(window).on('resize orientationchange', function() {
			  tallest = 0; heights.length = 0;               // reset the variables

			  items.each(function() {
				$(this).css('min-height', '0');              // reset each slide's height
			  });

			  normalizeHeights();                            // run it again
			});
		  }
		}
		/* jshint ignore:end */
		function sameHeightSub(container, ids, sub){
			
			console.log('dsmaeheighteSUB');
			$(ids).css('height', 'auto');
				var maxHeight = 0;
				$(ids).each(function(index) {
					if($(this).height() > maxHeight){ 
						maxHeight = $(sub, this).height();
					}	
				});
				$(ids).height(maxHeight);
			
			
		}
		function sameHeight(container, ids){
			
				$(ids).css('height', 'auto');
				var maxHeight = 0;
				$(ids).each(function(index) {
					//	console.log('h' + index + ' --' + $(this).height());
					if($(this).height() > maxHeight){ 
						maxHeight = $(this).height();
					}
				});
				$(ids).height(maxHeight);
			
			
		}
		
		$(window).on('load resize orientationchange', function () {
			
			if( $('.carousel .carousel-item').length ){
				carouselNormalization('.carousel .carousel-item');				
			}
			if( $('.home .teaserText').length ){
				sameHeight('.teaserBox', '.teaserText');
			}
			if( $('.boxBox').length ){
				sameHeight('.boxes', '.boxBox');
				//console.log('fire boxBox');
			}		
		});
		
		$('img.svg').each(function(){
            var $img = $(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');
			var imgWidth = $img.attr('width');
			var imgHeight = $img.attr('height');

            $.get(imgURL, function(data) {
                // Get the SVG tag, ignore the rest
                var $svg = $(data).find('svg');

                // Add replaced image's ID to the new SVG
                if(typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                // Add replaced image's classes to the new SVG
                if(typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass+' replaced-svg');
                }
				
				if(typeof imgWidth !== 'undefined') {
                    $svg = $svg.attr('width', imgWidth);
                }
				if(typeof imgHeight !== 'undefined') {
                    $svg = $svg.attr('height', imgHeight);
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');

                // Replace image with new SVG
                $img.replaceWith($svg);

            }, 'xml');

        });
		
		function startLoad(){	
			$(".carousel-inner").swiperight(function() {  
				  $(this).parent().carousel('prev');  
					});  
			   $(".carousel-inner").swipeleft(function() {  
				  $(this).parent().carousel('next');  
			});
			
			
			
			
		}
		function position(){
			
			var h = $('.page-header .headerImage').height();
			var r = 100;
			var nh = h-r;
			$('.sonnenBlume').css('top', nh);
			
		}

		$(window).on("scroll resize ready orientationchange load", function (){
				
					
					waitForFinalEvent(function(){						
						startLoad();						
					}, 500, "dgdj2");
			});
		$(window).on("resize ready orientationchange load", function (){
		//				$('.sonnenBlume').fadeOut();			
					if(window.innerWidth < 1440){	
						console.log('fire');
						position();
						if(window.innerWidth < 1024){
							
							var wh = $(window).height();
							var lh = wh - (wh / 100 * 60);
							
							if(window.matchMedia("(orientation: portrait)").matches){
									console.log('portrait');
									lh = wh - (wh / 100 * 80);
									$('.sonnenBlumeSticky').css('top', lh);
							position();	
							}else{
								
								
								$('.sonnenBlume').css('top', lh);
								$('.sonnenBlumeSticky').css('top', lh);
							}
						}
					}else{
						// Grosse Blume 
						var stilPt =  $('.page-header .headerImage').height() - 475;
						var stilPtSticky =  $('.page-header .headerImage').height() - 180;
						
						if($('body').hasClass('home')){
							
							stilPtSticky =  $('.page-header .headerImage').height() - 200;
							
						}
						
						var stilH = $('body').height() - $('.sonnenBlume .kopf').height() - stilPt;
						
						$('.sonnenBlume .stil').css('height',stilH);
						$('.sonnenBlumeSticky').css('top', stilPtSticky);
						
					}	

			/*			waitForFinalEvent(function(){						
						$('.sonnenBlume').fadeIn();							
						}, 500, "dgdj2dd");	
				*/		
						
			});	
			
			/*$('.page-header').bind('resize', function(){
				console.log('resizes');
				position();
				
			});
			
			$('.page-header').trigger('resize');*/
			
			
		$(document).ready(function () {
		  $('[data-toggle="offcanvas"]').click(function (e) {
			  e.preventDefault();
			$('.row-offcanvas').toggleClass('active');
			$('body').toggleClass('menuOpen');
		  });
		  
			$(document).keydown(function(e){

				if(e.keyCode === 27) {
					if ($('.row-offcanvas').hasClass('active')) {
						$('.row-offcanvas').removeClass('active');
						$('body').removeClass('menuOpen');
					}
				}
			});
		  
		 /* $('.row-offcanvas.active > .contentMain').click(function (e) {
			 console.log('CM clicked');
			$('.row-offcanvas').toggleClass('active');
		  });*/
		  
			$(document).on('click touchstart',function(e) {
				var target = e.target;
				if (!$(target).is('#sidebar') && !$(target).parents().is('#sidebar') && !$(target).is('.toggleIcon') && !$(target).is('.toggleIcon img')) {
					 $('.row-offcanvas').removeClass('active');
					 $('body').removeClass('menuOpen');
				}
			});
		  
		});	
		
		var offset = 300,
			//browser window scroll (in pixels) after which the "back to top" link opacity is reduced
			offset_opacity = 1200,
			//duration of the top scrolling animation (in ms)
			scroll_top_duration = 700,
			//grab the "back to top" link
			$back_to_top = $('.scroll-top');

			//hide or show the "back to top" link
			$(window).scroll(function(){
				/*( $(this).scrollTop() > offset ) ? $back_to_top.addClass('scroll-is-visible') : $back_to_top.removeClass('scroll-is-visible');*/
				
				if( $(this).scrollTop() > offset ){ 
					$back_to_top.addClass('scroll-is-visible');
				}else{	
					$back_to_top.removeClass('scroll-is-visible');
				}	
			});

			//smooth scroll to top
			$back_to_top.on('click', function(event){
				event.preventDefault();
				$('body,html').animate({
					scrollTop: 0 ,
					}, scroll_top_duration
				);
			});
			
		if($('.cn-more-info').length){
			
			$('.cn-more-info').removeClass('cn-button wp-default button');
		
		}
		
		$(".chb").change(function() {
			$(".chb").prop('checked', false);
			
			$('.field').removeClass("fehler");
			$('.fieldError').removeClass('showError');
			
			$(this).prop('checked', true);
			
			var v = $('#vaterwert').val();
			var m = $('#mutterwert').val();
			
			v = v.toLowerCase();
			v = v.replace('cm','');
			v = v.trim();
			
			
			m = m.toLowerCase();
			m = m.replace('cm','');
			m = m.trim();
			
			
			
			var e = 0;
			if(!$.isNumeric(v) || (100 > v) || (v > 250)){
				
				$('#vaterwert').addClass("fehler");
				$('.fieldError#vater').addClass('showError');
				$(".chb").prop('checked', false);
				e = 1;
			}
			
			if(!$.isNumeric(m) || (100 > m) || (m > 250)){
				
				$('#mutterwert').addClass("fehler");
				$('.fieldError#mutter').addClass('showError');
				$(".chb").prop('checked', false);
				e = 1;
			}
			
			v = parseInt(v);
			m = parseInt(m);
			
			var g = (m + v) / 2;
			
			var res;
			
			if (e<1){
				
				if($('#maedchen').is(':checked')){
					
					
					res = g - 6.5;
					
				}else{
					
					console.log('jungen');
					res = g + 6.5;
				}
				
				$('#kind').val(res + ' cm');
				$('#reset').show();
			}
			
		});

		$("#reset").click( function(){
             $('#reset').hide();
			 $(".chb").prop('checked', false);
			 $('#vaterwert').val("");
			 $('#mutterwert').val("");
			 $('#kind').val('');			 
        });
		
		
		function cookieAnsictFnc(){
			var cookieAnsicht = $('#cookie-notice').css('visibility');
		
			if(cookieAnsicht === "hidden"){
			
				$('#cookie-notice').addClass('cookie-notice-hidden');
			
			}
			if(cookieAnsicht === "visible"){
			
				$('#cookie-notice').removeClass('cookie-notice-hidden');
				
			}
		}
		
		cookieAnsictFnc();
	
		$('.cn-revoke-cookie').click(function(){ 
			$('#cookie-notice').removeClass('cookie-notice-hidden');
		});
		
		var delete_cookie = function(name) {
			document.cookie = name + '=; Path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
		};
		
		function getCookie(cname) {
			var name = cname + "=";
			var ca = document.cookie.split(';');
			for(var i = 0; i < ca.length; i++) {
				var c = ca[i];
				while (c.charAt(0) === ' ') {
					c = c.substring(1);
				}
				if (c.indexOf(name) === 0) {
					return c.substring(name.length, c.length);
				}
			}
			return "";
		}
		
		function checkCookie() {
			var user = getCookie("cookie_notice_accepted");
			if (user !== "") {
				if (user === "true" ) {
					//alert("Welcome again TRUE " + user);
					$('#cn-accept-cookie').addClass('selectedCookie');
					$('#cn-refuse-cookie').removeClass('selectedCookie');
				}else{
					//alert("Welcome again " + user);
					delete_cookie('_ga');
					delete_cookie('_gid');
					delete_cookie('_gat_gtag_UA_107255057_1');
					$('#cn-accept-cookie').removeClass('selectedCookie');
					$('#cn-refuse-cookie').addClass('selectedCookie');
				}
			}
			
		}
		
		function setCookie(cname, cvalue, exdays) {
			var d = new Date();
			d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
			var expires = "expires="+d.toUTCString();
			document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
		}
		
		checkCookie();
    
    /* Toogle Menü 20210408 */
    /* $(".main-menu-nav menu-item-has-children").click(function() {
      $(this).toggleClass('open');
      var $target = $(this).parent().next('.main-menu-nav menu-item-has-children').toggle();
      $('.main-menu-nav menu-item-has-children').not($target).hide();
    }); */
    
    $('.main-menu-nav .menu-item-has-children > a').click(function(e) {
      e.preventDefault();
    });
    
    $('.main-menu-nav .menu-item-has-children').click(function(e) {
        
        
        var $this = $(this);
           
        
      
        if ($this.find('.sub-menu').hasClass('show')) {
            $this.find('.sub-menu').removeClass('show');
            $this.find('.sub-menu').slideUp(350);
        } else {
            $this.parent().find('li .sub-menu').removeClass('show');
            $this.parent().find('li .sub-menu').slideUp(350);
            $this.find('.sub-menu').toggleClass('show');
            $this.find('.sub-menu').slideToggle(350);
        }
    });
    

    
    /* Toggle Menü Ende */
		
		
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
